import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAbPKXDzKx3lPQpmyWPtRFrWu6CsSnKUpA",
  authDomain: "pedicab-app-97008.firebaseapp.com",
  databaseURL:
    "https://pedicab-app-97008-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "pedicab-app-97008",
  storageBucket: "pedicab-app-97008.appspot.com",
  messagingSenderId: "489556850992",
  appId: "1:489556850992:web:753076290ce2fe257930f5",
  measurementId: "G-F7TWXLTQQE",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;

import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import PediCabMarker from "../../components/PediCabMarker";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import db from "../../firebase";

const Map = () => {
  const defaultProps = {
    center: {
      lat: 9.654874,
      lng: 123.864124,
      heading: 0,
    },
    zoom: 15,
  };

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const collectionRef = collection(db, "PediDrivers");
        const q = query(
          collectionRef,
          where("user.application_status", "==", "active")
        );
        const querySnapshot = await getDocs(q);

        const documentsArray = [];
        querySnapshot.forEach((doc) => {
          documentsArray.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        setDocuments(documentsArray);
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    };

    const unsubscribe = onSnapshot(collection(db, "PediDrivers"), () => {
      fetchDocuments();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAnkKtWhxP4gL5hsOGExrfjAjrY6dQlWHc" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {documents.map((document) => (
          <PediCabMarker
            key={document.data.user.id + document.data.id}
            lat={document.data.location.latitude}
            lng={document.data.location.longitude}
            heading={document.data.location.heading}
            data={document.data}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;

import React, { useState } from "react";
import InfoBox from "./InfoBox";

function PediCabMarker({ lat, lng, heading, data }) {
  const [isInfoBoxVisible, setInfoBoxVisible] = useState(false);

  return (
    <div
      lat={lat}
      lng={lng}
      className="map-marker"
      onMouseEnter={(event) => {
        setInfoBoxVisible(true);
      }}
      onMouseLeave={(event) => {
        setInfoBoxVisible(false);
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          zIndex: 1111,
          justifyContent: "center",
          alignItems: "center",
          transform: `translate(-50%, -50%) rotate(${heading}deg)`,
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 6,
            color: "#ffffff",
          }}
        >
          {data?.user?.driver_vehicle?.attributes?.body_number
            ? data?.user?.driver_vehicle?.attributes?.body_number
            : "0000"}
        </p>
      </div>
      <img
        src="/assets/images/trike.png"
        alt="marker1"
        style={{
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transform: `translate(-50%, -50%) rotate(${heading}deg)`,
        }}
      />
      {isInfoBoxVisible && <InfoBox data={data} />}
    </div>
  );
}

export default PediCabMarker;

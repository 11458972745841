import React from "react";
import { Routes, Route } from "react-router-dom";
import Map from "./pages/map/Map";
import { NotFound } from "./pages/errors";

function App() {
  return (
    <div className="antialiased">
      <Routes>
        <Route path="/10million" element={<Map />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

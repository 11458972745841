import React, { useRef, useState, useEffect } from "react";
import "./InfoBox.css";

const InfoBox = ({ data }) => {
  const infoBoxRef = useRef(null);
  const [infoBoxHeight, setInfoBoxHeight] = useState(0);

  useEffect(() => {
    if (infoBoxRef.current) {
      const height = infoBoxRef.current.clientHeight;
      setInfoBoxHeight(height);
    }
  }, [data]);

  const topOffset = -infoBoxHeight - 30;

  return (
    <div
      className="info-box"
      ref={infoBoxRef}
      style={{
        top: `${topOffset}px`,
        zIndex: 10000,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          width: 120,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={data.user.bio_detail.photo.url}
          style={{
            height: 80,
            width: 80,
            borderRadius: 80,
            objectFit: "cover",
          }}
        />
      </div>

      <div
        style={{
          width: 180,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <span>
          Driver's Name: <b>{data.user.full_name}</b>
        </span>
        <span>
          Body Number: <b>{data.user.driver_vehicle.attributes.body_number}</b>
        </span>
        <span>
          Latitude: <b>{data.location.latitude}</b>
        </span>
        <span>
          Longitude: <b>{data.location.longitude}</b>
        </span>
        <span>
          Heading: <b>{data.location.heading}</b>
        </span>
      </div>
    </div>
  );
};

export default InfoBox;
